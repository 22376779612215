<template>
  <div class="about">
 
    <van-cell-group>
       <div class="tit_test">当前用户：<span class="name">{{curName}}</span><span class="id">ID:{{curId}}</span></div>
      <van-field label="用户Id" v-model="userId" placeholder="请输入Id" />
       <van-cell title="" />
      <van-button size="small" @click="changeUser()" type="info">提交</van-button>
    </van-cell-group>
  </div>
</template>

<script>
import { Field } from 'vant';
import { Button } from 'vant';
import { Cell,CellGroup } from 'vant';
import { Toast } from 'vant';
import {sendPost} from '../assets/js/api.js'
export default {
          name: 'about',
          components: {
          [Cell.name]: Cell,
          [CellGroup.name]: CellGroup,  
          [Toast.name]: Toast,  
          [Field.name]: Field,
          [Button.name]: Button
      },
      data (){
        return {
        
            curName:"",
            curId:"",
            userId:""

         

        }
      },
      methods:{
          changeUser(){
               console.log("提交");
               console.log(this.userId);
               let _that = this;
              // this.data.userId = userId;
               if(!this.userId){
                  Toast('请输入id');
               }else{
                    localStorage.removeItem('token');
                    sendPost('api/test/login',{'user_id':_that.userId}).then(function(res){
                        let resData = res.data;
                        if(resData.code == 0){
                          localStorage.setItem('token',res.data.data.token);
                          localStorage.setItem('name',res.data.data.name);
                          localStorage.setItem('role',res.data.data.role);
                          localStorage.setItem('user_id',res.data.data.user_id);
                          localStorage.setItem('avatar',res.data.data.avatar);
                          localStorage.setItem('slug',res.data.data.slug);
                          localStorage.setItem('isSales',res.data.data.isSales);
                          _that.curName = localStorage.getItem('name');
                          _that.curId = localStorage.getItem('user_id');
                        }
                      });
               }
          }

      },
      created(){
          console.log(this.curName);
          this.curName = localStorage.getItem('name');
          this.curId = localStorage.getItem('user_id');


      }





}
</script>
<style scoped>
.tit_test{ font-size: 14px; padding: 20px 15px 20px 15px;}
.tit_test .name{ padding: 0 20px;}
</style>




